<template>
  <div id="app" class="container">
    <div class="form_box">
      <div class="form_item">
        <div class="label">
          <img src="/imgs/user.png" alt="">
        </div>
        <div class="input_box">
          <input type="text" placeholder="用户名" class="input" v-model="username">
        </div>
      </div>
      <div class="form_item">
        <div class="label">
          <img src="/imgs/pwd.png" alt="">
        </div>
        <div class="input_box">
          <input :type="pwdModel" placeholder="密码" class="input" v-model="password">
          <div class="icon_box" @click="pwdSwitch">
            <img v-if="pwdHide" src="/imgs/eye_off.png" alt="">
            <img v-else src="/imgs/eye.png" alt="">
          </div>
        </div>
      </div>
      <div class="submit_btn" @click="login">登录</div>
        <!-- <el-input placeholder="用户名" v-model="username"></el-input> -->
        <!-- <el-input placeholder="密码" v-model="password" show-password></el-input> -->
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      username: '',
      password: '',
      pwdModel: 'password',
      pwdHide: true,
    }
  },
  components: {
    // HelloWorld
  },
  methods:{
    // 表单验证
    login(){
      this.doLogin();
    },
    // 登录
    doLogin(){
      const params = {
        username: this.username,
        password: this.password,
      }
      axios.post((process.env.NODE_ENV=='production'? 'api/wms' : 'wms')+'/passport/login', params) // 发送GET请求
      .then(res => {
        // 处理响应数据
        console.log('成功',res.data);
        if(res.data.code === 0) {
          const token = res.data.result.token;
          window.localStorage.setItem('token', token);
          window.localStorage.setItem('user_id', res.data.result.user_id);
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          this.$message({
            type: 'success',
            message: '登录成功!',
            duration: 1500
          });
          this.$router.replace({
            path: '/'
          });
        }else{
          this.$message({
            type: 'error',
            message: res.data.message
          });
        }
      })
      .catch(err => {
        // 处理错误
        console.log('失败',err);
      });
    },
    pwdSwitch(){
      if(this.pwdHide){
        this.pwdModel = 'text';
        this.pwdHide = false;
      }else{
        this.pwdModel = 'password';
        this.pwdHide = true;
      }
    }
  }
}
</script>

<style lang="less">
.container {
  width: 100vw;
  height: 100vh;
  background-color: #2D3A4B;
  .form_box{
    width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .form_item{
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      background-color: #273343;
      border: 1px solid #ccc;
      margin-bottom: 20px;
      padding: 10px;
      .label{
        width: 20px;
        height: 20px;
        margin-right: 10px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .input_box{
        flex: 1;
        position: relative;
        .input{
          width: 100%;
          box-sizing: border-box;
          height: 30px;
          background-color: transparent;
          box-shadow: none;
          outline: none;
          border: none;
          color: #fff;
          font-size: 16px;
        }
        .icon_box{
          position: absolute;
          right: 0;
          top: 3px;
          cursor: pointer;
          img{
            width: 25px;
            height: 25px;
          }
        }
      }
    }
    .submit_btn{
      cursor: pointer;
      width: 100%;
      background-image: linear-gradient(to left,#0f75d7, #1891FB);
      text-align: center;
      color: #fff;
      padding: 8px 0;
    }
  }
}
</style>
