import Vue from 'vue';
import Router from 'vue-router';

// 导入需要路由的组件
import Device from '@/view/device.vue';
import Login from '@/view/login.vue';
import Product from '@/view/product.vue';

Vue.use(Router);

const router = new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Device',
      component: Device
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/product',
      name: 'Product',
      component: Product
    }
  ]
});
function checkUserLoggedIn(){
  return !!window.localStorage.getItem('token');
}
router.beforeEach((to, from, next) => {
  console.log(to, from, next);
  const isLoggedIn = checkUserLoggedIn(); // 根据实际情况判断用户是否已登录
  if (to.path !== '/login' && !isLoggedIn) {
    // 如果用户未登录且访问的不是登录页，则重定向到登录页
    next('/login');
  } else {
    // 已登录或者访问的是登录页，则正常跳转
    next();
  }
  // 全局前置守卫逻辑
});
export default router;
