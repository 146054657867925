<template>
  <div id="app">
    <div class="top_nav">
      <div class="logo_box">
        <!-- <img src="/imgs/left.jpg" alt=""> -->
      </div>
      <div class="top_center">
        <div class="export" @click="exportToZip(true)">导出全部二维码</div>
        <div class="export" @click="exportToZip()">
          导出二维码
          <div class="tips" v-if="showTip">
            暂无需要导出的新数据
          </div>
        </div>
        <div class="nav_item" @click="goTo('/')">机身码</div>
        <div class="nav_item active">产品码</div>
        <div class="logout" @click="logout()">退出登录</div>
      </div>
      <div class="logo_box">
        <!-- <img src="/imgs/right.png" alt=""> -->
      </div>
    </div>
    <div class="center_container">
      <div class="tab_box">
        <el-table
        :data="list"
        border 
        style="width: 100%">
          <el-table-column
            prop="id"
            label="产品码ID"
            width="80">
          </el-table-column>
          <el-table-column
            prop="name"
            label="产品码名称">
            <template slot-scope="scope">
              <div>
                {{scope.row.name || '产品码_'+scope.row.id}}
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="mac"
            label="产品码MAC"
            width="120">
          </el-table-column> -->
          <el-table-column
            prop="status"
            label="状态"
            width="70"
          >
            <template slot-scope="scope">
              {{scope.row.status===1? '可用' : '禁用'}}
            </template>
          </el-table-column>
          <el-table-column
            prop="create_time"
            label="创建时间"
            width="100"
          >
            <template slot-scope="scope">
              {{scope.row.create_time || '--'}}
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="操作"
            width="70">
            <template slot-scope="scope">
              <el-button style="color:#f56c6c" type="text" size="small" @click="del(scope.row.id,scope.row.index)">删除</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
    </div>
    <div class="pagination_box">
      <el-pagination
        style="margin-top: 15px;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-sizes="[25, 50, 100, 200, 500]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import axios from 'axios';
import JSZip from 'jszip';
export default {
  name: 'App',
  data() {
    return {
      list: [],
      page: 1, //当前页
      pageSize: 25, //分页大小
      total: 0, //列表数据总条数
      showTip: false
    }
  },
  components: {
    // HelloWorld
  },
  created(){
    this.getList();
  },
  methods:{
    async exportToZip(isAll=false) {
      let loadingMsg = this.$message({
        type: 'success',
        message: '下载中~',
        duration: 0,
      });
      this.showTip = false;
      try {
        const imageUrls = [];
        const items = [];
        const list = this.list;
        const idStr = localStorage.getItem('product_ids');
        const ids = idStr? JSON.parse(idStr) : [];
        const idArr = JSON.parse(JSON.stringify(ids));
        for(let i in list){
          const { id, name } = list[i];
          if(ids.indexOf(id)>-1 && !isAll){
            // 数据已导出过，跳过本次循环
            console.log('跳过循环',i,'值：',id);
            continue;
          }
          imageUrls.push(`/qrcode/qrcode?data=${id}&page=pages/index/index&type_name=product&type=2&text=产品码_${name}`);
          items.push({
            id,
            name
          });
          idArr.push(id);
        }
        if(items.length==0 && !isAll){
          this.showTip = true;
          return;
        }
        if(!isAll){
          // 将已导出过的图片保存到本地
          localStorage.setItem('product_ids', JSON.stringify(idArr));
        }
        console.log('保存前',imageUrls,'列表',list,'元素',items);
        // const imageUrls = this.images;
        const responses = await Promise.all(imageUrls.map(url => {
          // fetch(url)
          return axios.get((process.env.NODE_ENV=='production'? 'api/wms' : 'wms')+url, { responseType: 'blob' });
        }));
        const zip = new JSZip();
        responses.map((res, key) =>{
          console.log('对象',res,'--', key);
          zip.file(items[key].id+'_'+(items[key].name || '产品码')+'.png', new Blob([res.data]), { binary: true });
        });
        zip.generateAsync({ type: 'blob' }).then(blob => {
          // 创建一个指向 Blob 的 URL
          const url = window.URL.createObjectURL(blob);
          // 创建一个下载链接元素
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'archive.zip'); // 设置下载的文件名
          // 触发下载
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          loadingMsg.close();
          this.$message({
            type: 'success',
            message: '下载成功!'
          });
          // 释放 URL 对象
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error('Error downloading images:', error);
      }
    
    },
    logout(){
      this.$confirm('确定退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '退出登录成功!'
        });
        this.$router.replace({
          path: '/login'
        });
      }).catch(() => {
      });
    },
    goTo(path){
      this.$router.push({
        path
      });
    },
    del(id,idx){
      console.log(id,idx);
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doDel(id,idx);
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });
    },
    doDel(id,idx){
      const token = window.localStorage.getItem('token');
      if(token){
        axios.defaults.headers.common['Authorization'] = token;
      }
      axios.post((process.env.NODE_ENV=='production'? 'api/wms' : 'wms')+`/device.logs/del`,{id}) // 发送GET请求
      .then(res => {
        // 处理响应数据
        console.log('成功',res.data);
        if(res.data.code === 0) {
          this.list.splice(idx,1);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }else{
          this.$message({
            type: 'error',
            message: res.data.message
          });
          console.log('res.daat.code',res.data.code);
          if(res.data.code===401){
            this.$router.replace({
              path: '/login'
            });
          }
        }
      })
      .catch(err => {
        // 处理错误
        console.log('失败',err);
      });
    },
    getList(){
      const that = this;
      const token = window.localStorage.getItem('token');
      if(token){
        axios.defaults.headers.common['Authorization'] = token;
      }
      axios.get((process.env.NODE_ENV=='production'? 'api/wms' : 'wms')+`/device.product/getList?page=${this.page}&pageSize=${this.pageSize}&_t`+(new Date).getTime()) // 发送GET请求
      .then(res => {
        // 处理响应数据
        console.log('成功',res.data);
        if(res.data.code === 0) {
          that.total = res.data.result.total;
          this.list = res.data.result.data;
        }else{
          this.$message({
            type: 'error',
            message: res.data.message
          });
          console.log('res.daat.code',res.data.code);
          if(res.data.code===401){
            this.$router.replace({
              path: '/login'
            });
          }
        }
      })
      .catch(err => {
        // 处理错误
        console.log('失败',err);
      });
    },
    handleSizeChange(val){
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(){
      this.getList();
    },
  }
}
</script>

<style lang="less" scoped>
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: linear-gradient(to right, #f1f1f1, #fff);
  border-radius: 6px;
}

/* 滚动条滑块 */
*::-webkit-scrollbar-thumb {
  background: linear-gradient(to right, #9dc7f1, #7eaee2);
  border-radius: 6px;
}

/* 鼠标悬停在滚动条上时的滑块样式 */
*::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to right, #7eaee2, #5e93d3);
  border-radius: 6px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  .top_nav{
    width: 1020px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;
    box-sizing: border-box;
    .logo_box{
      width: 70px;
      box-sizing: border-box;
      img{
        width: 100%;
      }
    }
    .top_center{
      display: flex;
      align-items: center;
      justify-content: center;
      .nav_item{
        width: 80px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background-color: #fff;
        color: #606266;
        border: 1px solid #ccc;
        cursor: pointer;
        &.active{
          background-color: #338aff;
          border: 1px solid #338aff;
          color: #fff;
        }
      }
    }
    .nav_item:first{
      margin-right: 15px;
    }
    .export{
      width: 110px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background-color: #5b91f1;
      color: #fff;
      border: 1px solid #5b91f1;
      margin-right: 20px;
      font-size: 14px;
      cursor: pointer;
      position: relative;
      .tips{
        width: 200%;
        font-size: 14px;
        color: #777;
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    .logout{
      width: 65px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      background-color: #f56c6c;
      color: #fff;
      border: 1px solid #f56c6c;
      margin-left: 20px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .center_container{
    width: 1020px;
    height: calc(100vh - 155px);
    margin: 0 auto;
    display: flex;
    align-items: center;
    .tab_box{
      width: 100%;
      height: 100%;
      overflow: auto;
      box-shadow: 0 0px 3px #999;
    }
  }
  .pagination_box{
    width: 1020px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}
.offset_shadow{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  .offset_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    .form_item{
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      .form_label{
        margin-right: 15px;
        color: #333;
      }
      .form_input{
        outline: none;
        color: #333;
        padding: 5px 8px;
        border: 1px solid #777;
        line-height: 18px;
      }
    }
    .btns{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .set_btn{
        width: 98px;
        height: 38px;
        line-height: 38px;
        font-size: 18px;
        text-align: center;
        background-color: #5b91f1;
        color: #fff;
        border: 1px solid #5b91f1;
        margin-right: 20px;
        font-size: 14px;
        cursor: pointer;
        border-radius: 3px;
        &.cancel{
          color: #333;
          border-color: #aaa;
          background-color: #fff;
        }
      }
    }
  }
}
</style>
